import Siema from 'siema';

class Hero {
    constructor() {
        this.SLIDE_DURATION = 10000;
        this.HeroEl = document.querySelector('[data-ncomponent="hero"]');
        this.NextBtn = document.querySelector('[data-nbutton="hero-next"]');
        this.PrevBtn = document.querySelector('[data-nbutton="hero-prev"]');

        this.activeSlideIndex = 0;

        if (this.HeroEl) {
            this.init();
        }
    }

    init () {
        this.SlideEls = Array.from(this.HeroEl.querySelectorAll('[data-ncomponent="hero-slide"]'));

        this.Hero = new Siema({
            selector: this.HeroEl,
            duration: 0,
            easing: 'ease-out',
            perPage: 1,
            startIndex: this.activeSlideIndex,
            draggable: false,
            multipleDrag: false,
            threshold: 20,
            loop: true,
            rtl: false,
            onInit: () => {
                this.NextBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.Hero.next(1, () => this.setActiveSlide());
                });
                this.PrevBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.Hero.prev(1, () => this.setActiveSlide());
                });
                this.setActiveSlide();
            },
            onChange: () => {
                this.activeSlideIndex = this.Hero.currentSlide;
                this.setActiveSlide();
            },
        });

        this.isPaused = false;

        this.frame = () => {
            if (!this.isPaused)
            {
                this.clearTimer();
                this.NextBtn.click();
            }
        }
        this.timer = setInterval(this.frame, this.SLIDE_DURATION);

        this.HeroEl.addEventListener('mouseenter', () => {
            this.isPaused = true;
        });
        this.HeroEl.addEventListener('mouseleave', () => {
            this.isPaused = false;
            this.clearTimer();
        });
    }

    setActiveSlide() {
        if (this.activeSlideIndex > this.SlideEls.length - 1)
        {
            this.activeSlideIndex = 0;
        }

        if (this.activeSlideIndex < 0)
        {
            this.activeSlideIndex = this.SlideEls.length - 1;
        }
        
        this.SlideEls.forEach((slide, i) => {
            slide.classList.remove('active');

            if (i == this.activeSlideIndex)
            {
                slide.classList.add('active');
            }
        });
        this.clearTimer();
    }

    clearTimer() {
        clearInterval(this.timer);
        this.timer = setInterval(this.frame, this.SLIDE_DURATION);
    }
}

export default new Hero();
