import throttle from 'just-throttle';

class StickyHeader {
    constructor() {
        this.headerContainerEl = document.querySelector('[data-ncomponent="site-header-container"]');
        this.headerContainerPlaceholderEl = document.querySelector('[data-ncomponent="site-header-container-placeholder"]');
        this.headerEl = document.querySelector('[data-ncomponent="site-header"]');

        if (this.headerEl && this.headerContainerEl) {
            this.init();
        }
    }

    init() {
        window.addEventListener('scroll', throttle(() => this.updateHeader(), 100, true));
    }

    updateHeader() {
        const bottomOfHeaderContainer = this.headerContainerEl.offsetTop + this.headerContainerEl.getBoundingClientRect().height;
        
        this.headerContainerPlaceholderEl.style.height = `${this.headerEl.getBoundingClientRect().height}px`;

        if (window.pageYOffset > bottomOfHeaderContainer) {
            this.headerEl.classList.add('sticky');
            this.headerContainerPlaceholderEl.style.display = 'block';
        }
        else {
            this.headerEl.classList.remove('sticky');
            this.headerContainerPlaceholderEl.style.display = 'none';
        }
    }
}

export default new StickyHeader();
