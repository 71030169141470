import Sharer from './modules/share/sharer';
import AutoResizeVideos from './modules/videos/autoresize';
import ContentModuleGallery from './modules/galleries/cm-gallery';
import Hero from './modules/hero/hero';
import StickyHeader from './modules/header/sticky';
import 'picturefill';
//import AOS from 'aos';

// Picture element HTML5 shiv
document.createElement("picture");

/*
AOS.init({
    duration: 750
});
*/
